//Gamelist.js
import React, { useState, useEffect } from 'react';
import { fetchGames } from './api'; // Ensure this points to your API utility file
import './GameList.css';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs'; // Using react-icons for stylish buttons

const formatLeagueName = (leagueName) => {
    // Replace specific cases or use a more general approach based on your needs
    if (leagueName === 'PremierLeague') {
        return 'Premier League';
    }
    // Add more cases as needed or use a more generic string manipulation
    return leagueName;
};

const GameList = () => {
    const [games, setGames] = useState([]);
    const [showScores, setShowScores] = useState(true);

    const defaultFilterDate = process.env.NODE_ENV === 'development' ? '' : new Date().toISOString().split('T')[0];
    const [filterDate, setFilterDate] = useState(defaultFilterDate);
    const [filterTeam, setFilterTeam] = useState('');
    const [filteredGames, setFilteredGames] = useState([]);
    const [filterLeague, setFilterLeague] = useState(''); // New state for league filter


    useEffect(() => {
        const loadData = async () => {
            const data = await fetchGames();
            console.log('Data:', data);
            if (Array.isArray(data)) {
                setGames(data);
            }
        };
        loadData();
    }, []);

    useEffect(() => {
        const filtered = games.filter(game => {
            return (filterDate ? game.date === filterDate : true) &&
                (filterTeam ? game.home_team.toLowerCase().includes(filterTeam.toLowerCase()) ||
                    game.away_team.toLowerCase().includes(filterTeam.toLowerCase()) : true) &&
                (filterLeague ? game.league === filterLeague : true); // New league filter check
        });
        setFilteredGames(filtered);
    }, [games, filterDate, filterTeam, filterLeague]); // Add filterLeague to dependency array


    const handleDateChange = (e) => {
        setFilterDate(e.target.value);
    };

    const handleTeamChange = (e) => {
        setFilterTeam(e.target.value);
    };

    const toggleShowScores = () => {
        setShowScores(!showScores);
    };

    const changeDate = (offset) => {
        // If the current filter date is not set, use today's date.
        const currentDate = filterDate ? new Date(filterDate) : new Date();
        currentDate.setDate(currentDate.getDate() + offset);
        setFilterDate(currentDate.toISOString().split('T')[0]);
    };
    
    return (
        <div className="game-list-container">
            <h1 className="game-list-title">Games</h1>
            <div className="filters">
                <div className="date-filter">
                    <button className="date-nav-button" onClick={() => changeDate(-1)}>&#60;</button>
                    <input type="date" value={filterDate} onChange={handleDateChange}/>
                    <button className="date-nav-button" onClick={() => changeDate(1)}>&#62;</button>
                </div>

                <input type="text" placeholder="Filter by team..." value={filterTeam} onChange={handleTeamChange}/>
                <button onClick={toggleShowScores}
                        className="score-toggle">{showScores ? 'Hide Scores' : 'Show Scores'}</button>
            </div>
            <div className="league-filter">
                <select value={filterLeague} onChange={e => setFilterLeague(e.target.value)}>
                    <option value="">All Leagues</option>
                    <option value="NBA">NBA</option>
                    <option value="PremierLeague">Premier League</option>
                </select>
            </div>
            {Array.isArray(filteredGames) && filteredGames.length > 0 ? (
                <ul className="list-group game-list-group">
                    {filteredGames.map((game, index) => (
                        <li key={index} className="list-group-item game-list-group-item">
                            <div className="score-container">
                                <span className="team-name">{game.home_team}</span>
                                {showScores ? (
                                    <span className="game-score">{game.home_score} - {game.away_score}</span>
                                ) : (
                                    <span className="game-score-hidden"></span>
                                )}
                                <span className="team-name">{game.away_team}</span>
                                <span className="game-date">{game.date}</span>
                                <span className="game-location">{game.venue}</span>
                                <span className="league-badge">{formatLeagueName(game.league)}</span>
                            </div>
                        </li>
                    ))}
                </ul>
            ) : (
                <p>No games to display.</p>
            )}
        </div>
    );
};

export default GameList;