// src/api.js
import axios from 'axios';
import moment from 'moment'; // You might need to add moment.js to your project for this

// Set up the base URL for your Django backend server
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
console.log('API Base URL:', API_BASE_URL);
const dummyPlayers = [
    {
        "name": "LeBron James",
        "team": "Los Angeles Lakers",
        "nationality": "United States",
        "position": "Small Forward",
        "date_of_birth": "1984-12-30"
    },
    {
        "name": "Darvin Ham",
        "team": "Los Angeles Lakers",
        "nationality": "United States",
        "position": "Manager",
        "date_of_birth": "1973-07-23"
    },
    {
        "name": "D'Angelo Russell",
        "team": "Los Angeles Lakers",
        "nationality": "United States",
        "position": "Point Guard",
        "date_of_birth": "1996-02-23"
    },
    {
        "name": "Anthony Davis",
        "team": "Los Angeles Lakers",
        "nationality": "United States",
        "position": "Power Forward",
        "date_of_birth": "1993-03-11"
    },
    {
        "name": "Colin Castleton",
        "team": "Los Angeles Lakers",
        "nationality": "United States",
        "position": "Center",
        "date_of_birth": "2000-05-25"
    },
    {
        "name": "Max Christie",
        "team": "Los Angeles Lakers",
        "nationality": "United States",
        "position": "Shooting Guard",
        "date_of_birth": "2003-02-10"
    },
    {
        "name": "Alex Fudge",
        "team": "Los Angeles Lakers",
        "nationality": "United States",
        "position": "Small Forward",
        "date_of_birth": "2003-05-06"
    },
    {
        "name": "D'Moi Hodge",
        "team": "Los Angeles Lakers",
        "nationality": "British Virgin Islands",
        "position": "Shooting Guard",
        "date_of_birth": "1998-12-20"
    },
    {
        "name": "Jalen Hood-Schifino",
        "team": "Los Angeles Lakers",
        "nationality": "United States",
        "position": "Point Guard",
        "date_of_birth": "2003-06-19"
    },
    {
        "name": "Maxwell Lewis",
        "team": "Los Angeles Lakers",
        "nationality": "United States",
        "position": "Small Forward",
        "date_of_birth": "2002-07-27"
    },
    {
        "name": "Jarred Vanderbilt",
        "team": "Los Angeles Lakers",
        "nationality": "United States",
        "position": "Power Forward",
        "date_of_birth": "1999-04-03"
    },
    {
        "name": "Christian Wood",
        "team": "Los Angeles Lakers",
        "nationality": "United States",
        "position": "Power Forward",
        "date_of_birth": "1995-09-27"
    },
    {
        "name": "Jaxson Hayes",
        "team": "Los Angeles Lakers",
        "nationality": "United States",
        "position": "Center",
        "date_of_birth": "2000-05-23"
    },
    {
        "name": "Taurean Prince",
        "team": "Los Angeles Lakers",
        "nationality": "United States",
        "position": "Small Forward",
        "date_of_birth": "1994-03-22"
    },
    {
        "name": "Rui Hachimura",
        "team": "Los Angeles Lakers",
        "nationality": "Japan",
        "position": "Power Forward",
        "date_of_birth": "1998-02-08"
    },
    {
        "name": "Cam Reddish",
        "team": "Los Angeles Lakers",
        "nationality": "United States",
        "position": "Small Forward",
        "date_of_birth": "1999-09-01"
    },
    {
        "name": "Austin Reaves",
        "team": "Los Angeles Lakers",
        "nationality": "United States",
        "position": "Shooting Guard",
        "date_of_birth": "1998-05-29"
    },
    {
        "name": "Gabe Vincent",
        "team": "Los Angeles Lakers",
        "nationality": "Nigeria",
        "position": "Point Guard",
        "date_of_birth": "1996-06-14"
    },
    {
        "name": "Joshua Primo",
        "team": "Los Angeles Clippers",
        "nationality": "Canada",
        "position": "Shooting Guard",
        "date_of_birth": "2002-12-24"
    },
    {
        "name": "James Harden",
        "team": "Los Angeles Clippers",
        "nationality": "United States",
        "position": "Point Guard",
        "date_of_birth": "1989-08-26"
    },
    {
        "name": "Daniel Theis",
        "team": "Los Angeles Clippers",
        "nationality": "Germany",
        "position": "Center",
        "date_of_birth": "1992-04-04"
    },
    {
        "name": "Ivica Zubac",
        "team": "Los Angeles Clippers",
        "nationality": "Croatia",
        "position": "Center",
        "date_of_birth": "1997-03-18"
    },
    {
        "name": "Kawhi Leonard",
        "team": "Los Angeles Clippers",
        "nationality": "United States",
        "position": "Small Forward",
        "date_of_birth": "1991-06-29"
    },
    {
        "name": "Mason Plumlee",
        "team": "Los Angeles Clippers",
        "nationality": "United States",
        "position": "Power Forward",
        "date_of_birth": "1990-03-05"
    },
    {
        "name": "Russell Westbrook",
        "team": "Los Angeles Clippers",
        "nationality": "United States",
        "position": "Point Guard",
        "date_of_birth": "1988-11-12"
    },
    {
        "name": "Norman Powell",
        "team": "Los Angeles Clippers",
        "nationality": "United States",
        "position": "Small Forward",
        "date_of_birth": "1993-05-25"
    },
    {
        "name": "P. J. Tucker",
        "team": "Los Angeles Clippers",
        "nationality": "United States",
        "position": "Forward",
        "date_of_birth": "1985-05-05"
    },
    {
        "name": "Paul George",
        "team": "Los Angeles Clippers",
        "nationality": "United States",
        "position": "Small Forward",
        "date_of_birth": "1990-05-02"
    },
    {
        "name": "Bones Hyland",
        "team": "Los Angeles Clippers",
        "nationality": "United States",
        "position": "Point Guard",
        "date_of_birth": "2000-09-14"
    },
    {
        "name": "Amir Coffey",
        "team": "Los Angeles Clippers",
        "nationality": "United States",
        "position": "Shooting Guard",
        "date_of_birth": "1997-06-17"
    },
    {
        "name": "Terance Mann",
        "team": "Los Angeles Clippers",
        "nationality": "United States",
        "position": "Small Forward",
        "date_of_birth": "1996-10-18"
    },
    {
        "name": "Tyronn Lue",
        "team": "Los Angeles Clippers",
        "nationality": "United States",
        "position": "Manager",
        "date_of_birth": "1977-05-03"
    },
    {
        "name": "Brandon Boston Jr.",
        "team": "Los Angeles Clippers",
        "nationality": "United States",
        "position": "Shooting Guard",
        "date_of_birth": "2001-11-28"
    },
    {
        "name": "Jordan Miller",
        "team": "Los Angeles Clippers",
        "nationality": "United States",
        "position": "Small Forward",
        "date_of_birth": "2000-01-23"
    },
    {
        "name": "Xavier Moon",
        "team": "Los Angeles Clippers",
        "nationality": "United States",
        "position": "Shooting Guard",
        "date_of_birth": "1995-01-02"
    },
    {
        "name": "Kobe Brown",
        "team": "Los Angeles Clippers",
        "nationality": "United States",
        "position": "Small Forward",
        "date_of_birth": "2000-01-01"
    },
    {
        "name": "Moussa Diabaté",
        "team": "Los Angeles Clippers",
        "nationality": "France",
        "position": "Center",
        "date_of_birth": "2002-01-21"
    },
    {
        "name": "Josh Christopher",
        "team": "Utah Jazz",
        "nationality": "United States",
        "position": "Shooting Guard",
        "date_of_birth": "2001-12-08"
    },
    {
        "name": "Talen Horton-Tucker",
        "team": "Utah Jazz",
        "nationality": "United States",
        "position": "Shooting Guard",
        "date_of_birth": "2000-11-25"
    },
    {
        "name": "Will Hardy",
        "team": "Utah Jazz",
        "nationality": "United States",
        "position": "Manager",
        "date_of_birth": "1988-01-21"
    },
    {
        "name": "Danuel House Jr.",
        "team": "Utah Jazz",
        "nationality": "United States",
        "position": "Small Forward",
        "date_of_birth": "1993-06-07"

    }

    
    // Add more player objects as required
];
    const dummyGames = [
    { "date": "2022-10-05", "home_team": "Chicago Bulls", "away_team": "New Orleans Pelicans", "home_score": 125, "away_score": 129 },
    { "date": "2022-10-05", "home_team": "Portland Trail Blazers", "away_team": "Utah Jazz", "home_score": 101, "away_score": 118 },
    { "date": "2022-10-02", "home_team": "Houston Rockets", "away_team": "San Antonio Spurs", "home_score": 134, "away_score": 96 },
    { "date": "2022-10-02", "home_team": "Boston Celtics", "away_team": "Charlotte Hornets", "home_score": 134, "away_score": 93 },
    { "date": "2022-10-03", "home_team": "Brooklyn Nets", "away_team": "Philadelphia 76ers", "home_score": 108, "away_score": 127 },
    { "date": "2022-10-04", "home_team": "Portland Trail Blazers", "away_team": "Los Angeles Clippers", "home_score": 102, "away_score": 97 },
    { "date": "2022-10-04", "home_team": "Los Angeles Lakers", "away_team": "Sacramento Kings", "home_score": 75, "away_score": 105 },
    { "date": "2022-10-04", "home_team": "Miami Heat", "away_team": "Minnesota Timberwolves", "home_score": 111, "away_score": 121 },
    { "date": "2022-10-04", "home_team": "Memphis Grizzlies", "away_team": "Orlando Magic", "home_score": 109, "away_score": 97 },
    { "date": "2022-10-04", "home_team": "Denver Nuggets", "away_team": "Oklahoma City Thunder", "home_score": 101, "away_score": 112 }
    // Add more dummy game objects as needed
];

export const fetchGames = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/games?season=2023-2024`);
        if (Array.isArray(response.data)) {
            // Map over the games and reformat the date to 'YYYY-MM-DD'
            const formattedGames = response.data.map(game => ({
                ...game,
                date: moment(game.date).format('YYYY-MM-DD'), // Convert date format
                league: game.league
            }));
            return formattedGames;
        } else {
            console.log("Unexpected response format:", response.data);
            throw new Error('Unexpected response format');
        }
    } catch (error) {
        console.error('Error fetching games:', error.message);
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error(error.response.data);
            console.error(error.response.status);
            console.error(error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received
            console.error(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error', error.message);
        }
        return dummyGames
        ;
    }
};

// Function to fetch players with fallback to dummy data
export const fetchPlayers = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/players/api/players/`);
        if (Array.isArray(response.data) && response.data.length) {
            return response.data;
        } else {
            throw new Error('Invalid format or empty data');
        }
    } catch (error) {
        console.error('Error fetching players or API is down, using dummy data:', error);
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error(error.response.data);
            console.error(error.response.status);
            console.error(error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received
            console.error(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error', error.message);
        }
        return dummyPlayers; // Fallback to dummy data
    }
};